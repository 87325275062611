export const HeaderAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  show: {
    x: 1,
    opacity: 1,
  },
};
export const ImageAnimation = {
  hidden: {
    scale: 2,
    opacity: 0,
  },
  show: {
    scale: 1,
    opacity: 1,
  },
};
export const navbarAnimation={
    hidden:{
        y:-100
    },
    show:{y:0}

}
export const reveal={
    hidden:{x:-700},
    show:{x:0}
}
export const cardAnimation={
    hidden: {
        scale: 0,
        opacity: 0,
      },
      show: {
        scale: 1,
        opacity: 1,
      },
}
export const topContainerAnimation={
    hidden:{
        opacity:0
    },show:{
        opacity:1
    }
}
export const videoAnimation={
    hidden:{
        x:-800,
        opacity:0
    },show:{
        x:0,
        opacity:1
    }
}
export const reasonsAnimation={
    hidden:{
        x:800,
        opacity:0
    },
    show:{
        x:0,
        opacity:1
    }
}
export const testimonialAnimation={
    hidden:{
        opacity:0
    },show:{
        opacity:1
    }
}
export const fromUp={
    hidden:{y:200,opacity:0},
    show:{y:0,opacity:1}

}
export const fromDown={
    hidden:{y:-200,opacity:0},
    show:{y:0,opacity:1}

}
